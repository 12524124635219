.footer {
    padding: 50px 10px 0 40px;
    margin-top: 50px;
    border-top: 1px solid var(--gold);
    background-color: var(--assistant-color);
    margin-left: 370px;
}

.footer__text-wrapper {
    display: flex;
    justify-content: space-between;
}

.footer__text-block {
    width: 1250px;
}

.footer__text-logo {
    width: 140px;
}

.footer__text {
    font-family: "GothamPro-Light";
    margin-top: 10px;
    font-size: 14px;
}

.footer__social-network-wrapper {
    width: 200px;
    text-align: right;
}

.footer__social-network-title {
    font-family: "GothamPro-Light";
    font-size: 18px;
}

.footer__social-network-block {
    margin-top: 10px;
}

.footer__social-network {
    font-size: 20px;
    color: var(--black);
    border: 1px solid #ccc;
    padding: 7px;
    margin-left: 10px;
}

.footer__nav-wrapper {
    border-top: 1px solid var(--gold);
    margin-top: 30px;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
}

.footer__nav-rights-text {
    font-family: "GothamPro-Light";
}

.footer__nav-list {
    list-style: none;
    display: flex;
}

.footer__nav-item {
    margin: 0 10px;
}

.footer__nav-link {
    font-size: 22px;
    font-family: "Lato-Bold";
    color: var(--black);
    text-decoration: none;
    transition: .2s;
}

.footer__nav-link:hover {
    color: var(--gold);
}



@media screen and (max-width: 1200px) {
    .footer {
        margin-left: 310px;
    }

    .footer__text-logo {
        width: 100px;
    }

    .footer__social-network {
        font-size: 16px;
    }

    .footer__social-network-title {
        font-size: 15px;
    }

    .footer__text {
        font-size: 13px;
    }

    .footer__nav-rights-text {
        font-size: 13px;
    }

    .footer__nav-item {
        margin: 0 3px;
    }

    .footer__nav-link {
        font-size: 15px;
    }
}


@media screen and (max-width: 992px) {
    .footer {
        margin-left: 160px;
    }

    .footer__social-network-wrapper {
        display: none;
    }

    .footer__nav-wrapper {
        flex-direction: column-reverse;
        padding: 20px 0;
    }

    .footer__nav-list {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    .footer__nav-item {
        margin: 0 7px 0 0;
    }

    .footer__nav-link {
        font-size: 20px;
    }

    .footer__nav-rights-text {
        font-size: 10px;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        padding: 30px 10px 0 20px;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        margin-left: 110px;
    }
    .footer__text{
        font-size: 12px;
    }
}