.catalog-page__doors-wrapper {
    margin-top: 30px;
}


.catalog__filter {
    margin-top: 70px;
    display: flex;
    ;
    margin-bottom: 10px;
}

.catalog__filter-text {
    font-family: "Lato-Regular";
    margin-right: 15px;
    padding: 5px 10px;
    cursor: pointer;
    color: var(--black);
    opacity: .7;
    transition: .2s;
}

.catalog__filter-text--active {
    font-family: "Lato-Bold";
    color: var(--white);
    background-color: var(--gold);
    opacity: 1;
}

.catalog__filter-text:hover {
    color: var(--white);
    background-color: var(--gold);
    opacity: 1;
}



.catalog-page__doors-catalog {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}



@media screen and (max-width: 1500px) {
    .catalog__filter {
        width: 100%;
        overflow-x: scroll;
    }

    .catalog-menu::-webkit-scrollbar {
        height: 0 !important;
    }

    .catalog__filter-text {
        white-space: nowrap;
        margin-right: 10px;
    }
}

@media screen and (max-width: 992px) {
    .catalog__filter {
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    .catalog__filter-text {
        font-size: 13px;
    }
}