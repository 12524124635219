.door-page__title {
    font-size: 100px;
    font-family: "Lato-Regular";
    -webkit-text-stroke: 1px #6d6d6d;
    color: transparent;
}

.door-page__back-btn {
    font-size: 12px;
    width: 130px;
    padding: 10px 0px;
    margin-top: 20px;
    border: 1px solid #ccc;
    color: var(--black);
    background-color: transparent;
    text-transform: uppercase;
    font-family: "Lato-Bold";
    cursor: pointer;
    transition: .2s;
}

.door-page__back-btn:hover {
    background-color: var(--gold);
    color: var(--white);
    border: 1px solid var(--gold);
}