.form-success__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2c77;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.form-success__wrapper--active {
    opacity: 1;
    visibility: visible;
}

.form-success__block {
    position: relative;
    background-color: var(--white);
    width: 450px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
}

.form-success__text {
    font-size: 20px;
    font-family: "Lato-Regular";
}


@media screen and (max-width: 768px) {
    .form-success__text {
        font-size: 18px;
    }
}


@media screen and (max-width: 480px) {
    .form-success__block {
        width: 400px;
        height: 200px;
    }

    .form-success__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 360px) {
    .form-success__block {
        width: 400px;
        height: 180px;
    }
}