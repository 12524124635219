.door-types__title {
    font-family: "Lato-Thin";
    font-size: 40px;
    border-bottom: 2px solid var(--gold);
    margin: 50px 0;
    padding-bottom: 10px;
}

.door-types__types-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.door-types__types-block {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 49%;
    margin: 10px 0;
}

.door-types__types-block--img {
    width: 120px;
    margin-right: 10px;
}

.door-types__types-block--title {
    font-family: "Lato-Bold";
    color: var(--black);
    margin-bottom: 10px;
    font-size: 18px;
}

.door-types__types-block--subtitle {
    font-family: "Lato-Regular";
    color: var(--black);
    font-size: 14px;
}


@media screen and (max-width: 1500px) {
    .door-types__types-block {
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .door-types__types-block--title {
        font-size: 16px;
    }
}


@media screen and (max-width: 992px) {
    .door-types__title {
        font-size: 30px;
    }

    .door-types__types-block--subtitle {
        font-size: 12px;
    }

    .door-types__types-block--title {
        margin-bottom: 5px;
    }

    .door-types__types-block--img {
        width: 100px;
    }
}

@media screen and (max-width: 576px) {
    .door-types__title {
        margin: 30px 0;
        font-size: 25px;
    }

    .door-types__types-block {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .door-types__types-block--title {
        font-size: 20px;
    }

    .door-types__types-block--subtitle {
        font-size: 14px;
    }

    .door-types__types-block--img {
        width: 130px;
        margin: 10px 0 30px 0;
    }
}

@media screen and (max-width: 480px) {
    .door-types__title {
        margin: 30px 0;
    }


    .door-types__types-block--title {
        font-size: 18px;
    }

    .door-types__types-block--subtitle {
        font-size: 12px;
    }

    .door-types__types-block--img {
        width: 100px;
        margin: 10px 0 20px 0;
    }
}