.catalog-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.catalog-menu__link {
    padding: 7px 15px;
    font-size: 17px;
    font-family: "GothamPro-Light";
    color: var(--black);
    text-decoration: none;
    transition: .2s;
}

.catalog-menu__link--active {
    color: var(--white);
    background-color: var(--gold);
}

.catalog-menu__link:hover {
    color: var(--white);
    background-color: var(--gold);
}

@media screen and (max-width: 1500px) {
    .catalog-menu {
        width: 100%;
        overflow-x: scroll;
    }

    .catalog-menu::-webkit-scrollbar {
        height: 0 !important;
    }

    .catalog-menu__link {
        white-space: nowrap;
        margin-right: 10px;
    }
}


@media screen and (max-width: 768px) {
    .catalog-menu__link {
        font-size: 15px;
        margin-right: 7px;
        padding: 7px 10px;
    }
}

@media screen and (max-width: 480px) {
    .catalog-menu__link {
        font-size: 13px;
    }
}