.checkout-form__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c2c2c77;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.checkout-form__wrapper--active {
    opacity: 1;
    visibility: visible;
}

.checkout-form__wrapper form {
    position: relative;
    background-color: var(--white);
    padding: 70px 50px 40px 50px;
    width: 800px;
    margin: 0 20px;
    z-index: 999999999;
}