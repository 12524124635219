.basket {
    position: fixed;
    top: 20px;
    right: 25px;
    font-size: 35px;
    color: var(--gold);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    z-index: 9;
}

.basket--active {
    opacity: 1;
    visibility: visible;
}

.basket-count {
    position: absolute;
    right: -10px;
    top: -7px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    font-family: "GothamPro-Light";
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue);
    color: var(--white);
}



@media screen and (max-width: 576px) {
    .basket {
        top: 15px;
        right: 20px;
        font-size: 30px;
    }

    .basket-count {
        width: 15px;
        height: 15px;
    }
}


@media screen and (max-width: 480px) {
    .basket {
        font-size: 25px;
    }
}
@media screen and (max-width: 360px) {
    .basket {
        font-size: 20px;
    }
}