.we-produce__title {
    font-family: "Lato-Thin";
    font-size: 40px;
    border-bottom: 2px solid var(--gold);
    margin: 50px 0;
    padding-bottom: 10px;
}

.we-produce__product-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.we-produce__product-block {
    display: block;
    position: relative;
    margin: 10px 0;
    width: 49%;
}

.we-produce__product-block--img {
    width: 100%;
}

.we-produce__product-block--subtitle {
    font-family: "Lato-Regular";
    position: absolute;
    top: 13%;
    left: 0;
    padding: 10px;
    font-size: 18px;
    background-color: var(--assistant-color);
    color: var(--black);
}


@media screen and (max-width: 1200px) {
    .we-produce__product-block--subtitle {
        font-size: 15px;
    }
}

@media screen and (max-width: 992px) {
    .we-produce__product-block {
        margin: 10px 0;
        width: 100%;
    }

    .we-produce__title {
        font-size: 30px;
    }

    .we-produce__product-block--subtitle {
        font-size: 13px;
        padding: 7px;
    }
}


@media screen and (max-width: 576px) {
    .we-produce__title {
        margin: 30px 0;
        font-size: 25px;
    }
}