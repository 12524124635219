.works__wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.works__img {
    width: 49%;
    margin-bottom: 2%;
}


@media screen and (max-width: 992px) {
    .works__img {
        width: 100%;
        margin-bottom: 2%;
    }
}