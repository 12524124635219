.intro {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    font-family: "Lato-Regular";
    padding-left: 400px;
}

.intro__title {
    font-size: 65px;
    color: transparent;
    -webkit-text-stroke: 1.5px var(--white);
}

.intro__video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

@media screen and (max-width: 1200px) {
    .intro {
        padding-left: 360px;
    }

    .intro__title {
        font-size: 60px;
    }
}

@media screen and (max-width: 992px) {
    .intro {
        padding-left: 180px;
    }

    .intro__title {
        font-size: 50px;
    }
}

@media screen and (max-width: 768px) {
    .intro__title {
        font-size: 40px;
    }
}

@media screen and (max-width: 576px) {
    .intro__title {
        font-size: 30px;
    }

    .intro {
        padding-left: 170px;
    }
}

@media screen and (max-width: 480px) {
    .intro__title {
        font-size: 25px;
    }

    .intro {
        padding-left: 120px;
    }
}

@media screen and (max-width: 360px) {
    .intro__title {
        font-size: 20px;
    }
}