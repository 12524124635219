* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*::selection {
    background-color: var(--blue);
    color: var(--gold);

}

::-webkit-scrollbar {
    width: 0 !important;
}

:root {
    --gold: #d3b665;
    --blue: #273d7c;
    --black: #000;
    --red: #b52025;
    --white: #fff;
    --assistant-color: #f1f1f1;
    --bar-bg: #FFFFFF30;
}

@font-face {
    font-family: "Lato-Bold";
    src: url('fonts/Lato-Bold.woff2');
}

@font-face {
    font-family: "Lato-Regular";
    src: url('fonts/Lato-Regular.woff2');
}

@font-face {
    font-family: "Lato-Thin";
    src: url('fonts/Lato-Thin.woff2');
}

@font-face {
    font-family: "GothamPro-Light";
    src: url('fonts/GothamPro-Light.woff2');
}






.padding-wrapper {
    padding-left: 420px;
    padding-right: 10px;
}

.page-title {
    font-size: 65px;
    color: var(--gold);
    font-family: "Lato-Regular";
    margin-top: 50px;
    border-bottom: 2px solid var(--gold);
    padding-bottom: 10px;
}

.form-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 30px;
    transition: .2s;
    cursor: pointer;
}

.form-close-icon:hover {
    transform: scale(.9);
}

@media screen and (max-width: 1200px) {
    .padding-wrapper {
        padding-left: 330px;
    }
}


@media screen and (max-width: 992px) {
    .padding-wrapper {
        padding-left: 180px;
    }

    .page-title {
        font-size: 60px;
    }
}

@media screen and (max-width: 768px) {
    .page-title {
        font-size: 45px;
    }
}

@media screen and (max-width: 576px) {
    .form-close-icon {
        font-size: 25px;
    }
}

@media screen and (max-width: 480px) {
    .padding-wrapper {
        padding-left: 120px;
    }

    .page-title {
        font-size: 35px;
    }
}

@media screen and (max-width: 360px) {
    .page-title {
        font-size: 30px;
    }
}