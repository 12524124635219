.preloader-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader {
    z-index: 999999;
    position: absolute;
    background-color: var(--white);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.preloader-text {
    font-family: "GothamPro-Light";
    -webkit-text-stroke: 2px var(--gold);
    font-size: 120px;
    z-index: 999999;
    color: transparent;
}



@media screen and (max-width: 1200px) {
    .preloader-text {
        font-size: 110px;
    }
}

@media screen and (max-width: 992px) {
    .preloader-text {
        font-size: 90px;
    }
}

@media screen and (max-width: 768px) {
    .preloader-text {
        font-size: 60px;
    }
}

@media screen and (max-width: 576px) {
    .preloader-text {
        font-size: 50px;
    }
}

@media screen and (max-width: 480px) {
    .preloader-text {
        font-size: 40px;
    }
}
@media screen and (max-width: 360px) {
    .preloader-text {
        font-size: 30px;
    }
}