.menu {
    transform: translateY(-100%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 99;
    background-color: var(--white);
    transition: .7s;
    transition-delay: .5s;
}

.menu--active {
    transform: translateY(0);
    transition-delay: .1s;
}

.nav {
    padding-left: 250px;
}

.nav-list {
    list-style: none;
}

.nav-item {
    margin-bottom: 10px;
    overflow: hidden;
    height: 70px;
}

.nav-item--span {
    display: inline-block;
}

.nav-link {
    font-family: "Lato-Thin";
    font-size: 70px;
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    transition: color .2s;
}

.nav-link--active {
    color: var(--gold);
}

.nav-link:hover {
    color: var(--gold);
}


@media screen and (max-width: 1200px) {
    .nav {
        padding-left: 170px;
    }

    .nav-link {
        font-size: 60px;
    }

    .nav-item {
        margin-bottom: 5px;
        height: 60px;
    }
}

@media screen and (max-width: 768px) {
    .nav {
        padding-left: 130px;
    }

    .nav-link {
        font-size: 50px;
    }

    .nav-item {
        height: 50px;
    }
}

@media screen and (max-width: 480px) {
    .nav {
        padding-left: 70px;
    }

    .nav-link {
        font-size: 40px;
    }

    .nav-item {
        height: 40px;
    }
}

@media screen and (max-width: 360px) {
    .nav-link {
        font-size: 35px;
    }

    .nav-item {
        height: 35px;
    }
}