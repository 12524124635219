.doors-opens__wrapper {
    font-family: "Lato-Regular";
}

.doors-opens__wrapper-title {
    font-family: "Lato-Thin";
    font-size: 40px;
    margin-top: 60px;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--gold);
}

.doors-opens {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.doors-opens__block {
    display: flex;
    align-items: center;
    width: 28%;
    margin-right: 8%;
    margin-bottom: 2%;
}

.doors-opens__block:nth-child(3n) {
    margin-right: 0;
}


.doors-opens__block-text {
    margin-left: 10px;
    font-size: 15px;
}




@media screen and (max-width: 992px) {
    .doors-opens__wrapper-title {
        font-size: 30px;
    }

    .doors-opens__block-text {
        font-size: 12px;
    }

    .doors-opens__block-img {
        width: 50px;
    }

    .doors-opens__block {
        width: 46%;
    }

    .doors-opens__block:nth-child(3n) {
        margin-right: 8%;
    }

    .doors-opens__block:nth-child(2n) {
        margin-right: 0;
    }
}

@media screen and (max-width: 576px) {
    .doors-opens__wrapper-title {
        margin: 30px 0;
        font-size: 25px;
    }

    .doors-opens__block {
        width: 100%;
        margin-right: 0;
    }

    .doors-opens__block:nth-child(3n) {
        margin-right: 0;
    }
}