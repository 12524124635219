.contacts-text__wrapper {
    margin-top: 20px;
}

.contacts-text {
    font-family: "GothamPro-Light";
    margin-bottom: 7px;
    font-size: 18px;
    color: var(--black);
}

.contacts-text a {
    margin-left: 7px;
    font-family: "Lato-Bold";
    font-size: 16px;
    color: var(--blue);
}

.contacts-text span {
    margin-left: 7px;
    font-family: "Lato-Bold";
    font-size: 16px;
    color: var(--blue);
}

.map-wrapper {
    margin-top: 40px;
}


@media screen and (max-width: 992px) {
    .contacts-text {
        font-size: 16px;
    }

    .contacts-text a {
        font-size: 15px;
    }

    .contacts-text span {
        font-size: 15px;
    }
}

@media screen and (max-width: 480px) {
    .contacts-text {
        font-size: 16px;
        display: flex;
        flex-direction: column;
    }

    .contacts-text a {
        margin-left: 0;
        margin-top: 5px;
    }

    .contacts-text span {
        margin-left: 0;
        margin-top: 5px;
    }
}