.vertical-text-column {
    height: 100%;
    width: 150px;
    display: flex;
    justify-content: center;
    background-color: var(--bar-bg);
    border-right: 1px solid var(--gold);
    cursor: default;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 220px;
    font-family: "Lato-Regular";
    transition: .8s;
}

.vertical-text-wrapper {
    display: flex;
    justify-content: flex-start;
    transform: rotate(180deg) skew(360deg, 0deg);
    white-space: nowrap;
    overflow: hidden;
    writing-mode: vertical-lr;
    font-size: 110px;
    opacity: .5;
}

.vertical-text {
    margin: 10px 0;
    -webkit-text-stroke: 2px var(--blue);
    color: transparent;
}

.vertical-text span {
    -webkit-text-stroke: 2px var(--gold);
    color: var(--gold);
}




.vertical-text__location-column {
    height: 100%;
    width: 100px;
    padding: 20px 0;
    background-color: var(--bar-bg);
    border-right: 1px solid var(--gold);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 120px;
}

.vertical-text__location {
    font-size: 30px;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    font-family: "Lato-Thin";
    color: var(--black);
}


.vertical-text__progress-block {
    width: 70px;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-text__progress-img {
    width: 100%;
    position: absolute;
}

.vertical-text__progress-item {
    font-size: 13px;
    font-family: "GothamPro-Light";
    padding: 4px 0 0 3px;
}



@media screen and (max-width: 1200px) {
    .vertical-text-column {
        width: 110px;
        left: 200px;
    }

    .vertical-text-wrapper {
        font-size: 90px;
    }

    .vertical-text__location-column {
        left: 100px;
    }
}


@media screen and (max-width: 992px) {
    .vertical-text-column {
        width: 80px;
        left: 80px;
    }

    .vertical-text-wrapper {
        font-size: 65px;
    }

    .vertical-text__location-column {
        width: 80px;
        left: 0;
        padding: 90px 0 20px 0;
        border: none;
        background-color: transparent;
    }

    .vertical-text__location {
        font-size: 25px;
    }

    .vertical-text__progress-block {
        width: auto;
        height: auto;
    }

    .vertical-text__progress-img {
        display: none;
    }
}


@media screen and (max-width: 480px) {
    .vertical-text-column {
        width: 60px;
        left: 50px;
    }

    .vertical-text-wrapper {
        font-size: 50px;
    }

    .vertical-text__location-column {
        width: 50px;
    }

    .vertical-text__location {
        font-size: 20px;
    }

}