.door-details__wrapper {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
}

.door-details__img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.door-details__img {
    width: 100%;
}

.door-details__btn {
    font-size: 12px;
    width: 100%;
    white-space: nowrap;
    padding: 12px 30px;
    margin-top: 20px;
    border: 1px solid #ccc;
    color: var(--black);
    background-color: transparent;
    text-transform: uppercase;
    font-family: "Lato-Bold";
    cursor: pointer;
    transition: .2s;
}

.door-details__btn:hover {
    background-color: var(--gold);
    color: var(--white);
    border: 1px solid var(--gold);
}

.door-details__btn--delete {
    color: var(--red);
    border: 1px solid var(--red);
}

.door-details__btn--delete:hover {
    color: var(--white);
    background-color: var(--red);
    border: 1px solid var(--red);
}


.door-details {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 70%;
}

.door-details__text-wrapper {
    margin-top: 2%;
}

.door-details__text {
    font-size: 15px;
    font-family: "Lato-Regular";
    color: #888888;
    margin-bottom: 1%;
}






.door-details__colors-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.door-details__colors-block {
    width: 45px;
    height: 45px;
    cursor: pointer;
    margin-right: 3%;
    margin-bottom: 3%;
}


.door-details__colors-img {
    width: 100%;
    height: 100%;
    padding: 2px;
    border: 2px solid transparent;
    transition: .3s;
}

.door-details__colors-img--active {
    border: 2px solid var(--gold);
    transform: scale(.9);
}

.door-details__colors-title {
    font-family: "GothamPro-Light";
    font-size: 11px;
    text-align: center;
}









.door-details__types-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3%;
    margin-bottom: 5%;
}

.door-details__types-block {
    cursor: pointer;
    margin-right: 1%;
    width: 140px;
}


.door-details__types-img {
    width: 100%;
    padding: 5px;
    border: 2px solid transparent;
    transition: .3s;
}

.door-details__types-img--active {
    border: 2px solid var(--gold);
    box-shadow: 0 0 5px var(--gold);
    transform: scale(.97);
}

.door-details__types-title {
    font-family: "GothamPro-Light";
    font-size: 12px;
    text-align: center;
}


@media screen and (max-width: 1450px) {
    .door-details__colors-block {
        margin-bottom: 5%;
    }
}

@media screen and (max-width: 1200px) {
    .door-details__types-block {
        width: 30%;
    }

    .door-details__img-wrapper {
        width: 350px;
    }

    .door-details__colors-block {
        margin-bottom: 7%;
    }
}


@media screen and (max-width: 992px) {
    .door-details__types-block {
        width: 30%;
    }

    .door-page__title {
        font-size: 70px;
    }

    .door-details__colors-block {
        margin-bottom: 10%;
    }
}


@media screen and (max-width: 768px) {
    .door-details__wrapper {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10%;
    }

    .door-details__img-wrapper {
        width: 70%;
    }

    .door-details {
        width: 100%;
    }

    .door-details__types-block {
        width: 24%;
    }

    .door-page__back-btn {
        width: 100px;
        padding: 7px;
    }

    .door-details__btn {
        padding: 10px;
    }
}


@media screen and (max-width: 576px) {
    .door-details__types-block {
        width: 32%;
    }

    .door-details__img-wrapper {
        width: 100%;
    }

    .door-details__text {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .door-details__colors-wrapper {
        justify-content: space-between;
    }

    .door-details__colors-block {
        width: 40px;
        height: 40px;
        margin-right: 5%;
    }

    .door-page__back-btn {
        font-size: 11px;
        width: 90px;
    }

    .door-details__btn {
        font-size: 11px;
    }

    .door-details__colors-title {
        font-size: 10px;
    }
}

@media screen and (max-width: 480px) {
    .door-details__types-block {
        width: 32%;
    }

    .door-page__title {
        font-size: 50px;
    }

    .door-page__back-btn {
        font-size: 10px;
    }

    .door-details__btn {
        font-size: 10px;
    }
}

@media screen and (max-width: 360px) {
    .door-details__types-block {
        width: 48%;
    }

    .door-page__title {
        font-size: 30px;
        margin-top: 5%;
    }
}