.form__title {
    font-family: "Lato-Thin";
    font-size: 40px;
    border-bottom: 2px solid var(--gold);
    margin: 50px 0;
    padding-bottom: 10px;
}

.form-wrapper {
    display: flex;
    justify-content: space-between;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
}

.form__input-label {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.form__input-icon {
    width: 30px;
    height: 30px;
    font-size: 18px;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form__input {
    width: 100%;
    height: 45px;
    padding-left: 10px;
    font-size: 16px;
    font-family: "Lato-Bold";
    background-color: var(--assistant-color);
    border: 1px solid #ccc;
}

.form__input:focus {
    outline: none;
}

.input-empty {
    border: 1px solid var(--red);
}

.input-empty::placeholder {
    color: var(--red);
}

.form__textarea {
    min-height: 40px;
    padding-top: 15px;
}


.form__btn-block {
    text-align: right;
}

.form__btn {
    width: 200px;
    padding: 12px 30px;
    margin-top: 20px;
    border: 1px solid #ccc;
    color: var(--black);
    background-color: transparent;
    text-transform: uppercase;
    font-family: "Lato-Bold";
    cursor: pointer;
    transition: .2s;
}

.form__btn:hover {
    background-color: var(--gold);
    color: var(--white);
    border: 1px solid var(--gold);
}

.form__contact-wrapper {
    background-color: var(--assistant-color);
    font-family: "GothamPro-Light";
    width: 550px;
    padding: 30px;
}

.form__contact-title {
    font-family: "Lato-Thin";
    font-size: 30px;
    border-bottom: 2px solid var(--gold);
    margin: 20px 0 30px 0;
    padding-bottom: 10px;
}

.form__contact-email-block {
    margin-bottom: 30px;
}

.form__contact-email-title,
.form__contact-tel-title {
    color: var(--black);
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.form__contact-email,
.form__contact-tel {
    color: var(--blue);
    font-size: 18px;
    letter-spacing: 1px;
}



@media screen and (max-width: 1200px) {
    .form__input {
        font-size: 15px;
    }

    .form__contact-title {
        font-size: 28px;
    }

    .form__contact-email-title,
    .form__contact-tel-title {
        font-size: 18px;
    }

    .form__contact-email,
    .form__contact-tel {
        font-size: 15px;
    }
}


@media screen and (max-width: 1200px) {
    .form__title {
        font-size: 30px;
    }
}

@media screen and (max-width: 992px) {
    .form-wrapper {
        flex-direction: column;
    }

    .form__contact-title {
        display: none;
    }

    .form__contact-wrapper {
        display: none;
    }

    .form__btn {
        margin: 0;
        font-size: 13px;
    }

    .form__input {
        font-size: 13px;
    }
}


@media screen and (max-width: 576px) {
    .form__btn {
        width: 100%;
    }
}