.about-page__content-block {
    margin-top: 50px;
}

.about-page__text {
    width: 100%;
    font-family: "Lato-Regular";
    font-size: 18px;
    margin-bottom: 20px;
}

.about-page__text span {
    color: var(--gold);
    font-size: 22px;
}






@media screen and (max-width: 1200px) {
    .about-page__text {
        font-size: 16px;
    }

    .about-page__text span {
        font-size: 20px;
    }
}

@media screen and (max-width: 992px) {
    .about-page__text {
        font-size: 15px;
    }

    .about-page__text span {
        font-size: 18px;
    }
}