.bar__logo-column {
    padding: 20px 0;
    height: 100%;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bar-bg);
    border-right: 1px solid var(--gold);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.logo {
    width: 50px;
}

.logo-column__social-network-wrapper {
    display: flex;
    flex-direction: column;
}

.logo-column__social-network--link {
    color: var(--gold);
    opacity: .5;
    font-size: 22px;
    margin-top: 15px;
    transition: .2s;
}

.logo-column__social-network--link:hover {
    opacity: 1;
}




/* btn */
.menu-btn__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--black);
    cursor: pointer;
    font-size: 13px;
    font-family: "Lato-Regular";
}

.menu-btn__wrapper:hover .line-1 {
    top: 11px;
}

.menu-btn__wrapper:hover .line-2 {
    bottom: 11px;
}

.menu-btn {
    width: 25px;
    height: 25px;
    position: relative;
}

.menu-btn__line {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--gold);
}

.line-1 {
    top: 5px;
    transition: .2s;
}

.line-2 {
    bottom: 5px;
    transition: .2s;
}

.line-1--active {
    transform: rotate(45deg) translate(5px, 4px);
}

.line-2--active {
    transform: rotate(-45deg) translate(5px, -5px);
}

.menu-btn__wrapper:hover>.menu-btn .line-1--active {
    transform: rotate(45deg) translate(5px, 4px);
    top: 5px;
}

.menu-btn__wrapper:hover>.menu-btn .line-2--active {
    transform: rotate(-45deg) translate(5px, -5px);
    bottom: 5px;
}

/* btn-end */




@media screen and (max-width: 1200px) {
    .bar__logo-column {
        width: 100px;
    }

    .menu-btn__wrapper:hover .line-1 {
        top: 5px;
    }

    .menu-btn__wrapper:hover .line-2 {
        bottom: 5px;
    }
}

@media screen and (max-width: 992px) {
    .bar__logo-column {
        width: 80px;
    }

    .menu-btn__wrapper {
        font-size: 11px;
    }

    .logo {
        width: 40px;
    }

    .logo-column__social-network--link {
        font-size: 20px;
    }

    .logo-column__social-network-wrapper {
        margin-bottom: 25px;
    }
}



@media screen and (max-width: 480px) {
    .bar__logo-column {
        width: 50px;
    }

    .menu-btn__wrapper {
        font-size: 10px;
    }
}