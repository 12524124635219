.yt-video {
    width: 49%;
    margin-bottom: 2%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-video__play-icon {
    position: absolute;
    color: var(--gold);
    text-shadow: 0 0 10px var(--gold);
    font-size: 3vw;
    cursor: pointer;
    opacity: 1;
    visibility: visible;
    transition: .4s;
    transition-delay: .3s;
}

.yt-video__play-icon--active {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0;
    transition: 0;
}

.yt-video__img {
    width: 100%;
}

.iframe__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000c4;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.iframe__wrapper--active {
    opacity: 1;
    visibility: visible;
}

.iframe__wrapper-close-icon {
    position: fixed;
    top: 0;
    right: 0;
    color: var(--gold);
    background-color: #ffffff21;
    padding: 10px 15px;
    font-size: 25px;
    cursor: pointer;
}

.yt-video__iframe {
    aspect-ratio: 32 / 18;
    width: 75%;
    border: none;
}




@media screen and (max-width: 992px) {
    .yt-video {
        width: 100%;
    }

    .yt-video__play-icon {
        font-size: 7vw;
    }
}

@media screen and (max-width: 768px) {
    .yt-video__iframe {
        aspect-ratio: 32 / 18;
        width: 90%;
        border: none;
    }
}

@media screen and (max-width: 480px) {
    .iframe__wrapper-close-icon {
        font-size: 20px;
    }
}