.door-card {
    display: block;
    text-decoration: none;
    width: 32%;
    height: 26vw;
    margin-right: 2%;
    margin-bottom: 1.5%;
    background-repeat: no-repeat;
    background-size: cover;
}

.door-card:nth-child(3n) {
    margin-right: 0;
}


.door-card__title {
    font-size: 25px;
    font-family: "Lato-Regular";
    color: var(--gold);
    margin-top: 5px;
    padding-left: 10px;
    border-left: 2px solid var(--gold);
}


@media screen and (max-width: 768px) {
    .door-card {
        width: 49%;
        height: 44vw;
    }

    .door-card:nth-child(3n) {
        margin-right: 2%;
    }

    .door-card:nth-child(2n) {
        margin-right: 0;
    }

    .door-card__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 576px) {
    .door-card__title {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .door-card {
        width: 100%;
        height: 100vw;
        margin-right: 0;
        margin-bottom: 4%;
    }

    .door-card:nth-child(3n) {
        margin-right: 0;
    }
}