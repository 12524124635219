.basket-page__section {
    min-height: calc(100vh - 430px);
}

.basket-page__btn-wrapper {
    display: flex;
    align-items: center;
}

.basket-page__btn {
    font-size: 12px;
    font-family: "Lato-Bold";
    width: 130px;
    padding: 10px 0px;
    margin-top: 20px;
    border: 1px solid #ccc;
    color: var(--black);
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    transition: .2s;
}

.basket-page__btn:hover {
    background-color: var(--gold);
    color: var(--white);
    border: 1px solid var(--gold);
}

.basket-page__back-btn {
    margin-right: 15px;
}


.basket-list {
    padding: 20px 0;
}

.basket-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid var(--gold);
    font-family: "Lato-Regular";
}

.basket-item__text-wrapper {}

.basket-item__text {
    font-size: 22px;
    margin-bottom: 10px;
    color: #a7a7a7;
}

.basket-item__text span {
    color: var(--gold);
}

.basket-item__img {
    width: 150px;
    margin-right: 15px;
}


.basket-item__delete-btn {
    color: var(--red);
    border: 1px solid var(--red);
    font-size: 10px;
    width: 70%;
    white-space: nowrap;
    padding: 10px;
    margin-top: 10px;
    background-color: transparent;
    text-transform: uppercase;
    font-family: "Lato-Bold";
    cursor: pointer;
    transition: .2s;
}

.basket-item__delete-btn:hover {
    color: var(--white);
    background-color: var(--red);
    border: 1px solid var(--red);
}


@media screen and (max-width: 768px) {
    .basket-page__btn {
        padding: 7px;
    }

    .basket-item__img {
        width: 100px;
    }

    .basket-item__text {
        font-size: 18px;
    }
}

@media screen and (max-width: 576px) {
    .basket-page__btn {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .basket-page__btn-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .basket-page__btn {
        font-size: 10px;
        margin-top: 5%;
    }

    .basket-item__img {
        width: 70px;
    }

    .basket-item__text {
        font-size: 16px;
    }
}

@media screen and (max-width: 360px) {
    .basket-item__img {
        width: 50px;
    }

    .basket-item__text {
        font-size: 14px;
    }

    .basket-page__btn {
        width: 100%;
    }
}